<template>
    <div>
        <div class="mt-3 ml-1 vx-row">
            <div class="mb-6 vx-row" style="width:70%" v-if="this.isSuperAdmin">
                <div class="flex items-center w-full vx-col sm:w-1/4">
                    <span>Warehouse</span>
                </div>
                <div class="w-full vx-col sm:w-3/4">
                    <CustomMSelect 
                        base-url="/api/wms/v1/master/warehouses" 
                        label="name" 
                        track-by="name" 
                        :value="selectedSrcWarehouse"
                        @selected="(val) => { saveSelectedWarehouse(val) }"
                    ></CustomMSelect>
                </div>
            </div>
            <div class="mb-6 vx-row" style="width:70%">
                <div class="flex items-center w-full vx-col sm:w-1/4">
                    <span>Supplier</span>
                </div>
                <div class="w-full vx-col sm:w-3/4">
                    <CustomMSelect 
                        base-url="/api/wms/v1/master/suppliers" 
                        label="name" 
                        track-by="name" 
                        :value="selectedSupplier"
                        @selected="(val) => { saveSelectedSupplier(val) }"
                    ></CustomMSelect>
                </div>
            </div>
            <div class="mb-6 vx-row" style="width:70%">
                <div class="flex items-center w-full vx-col sm:w-1/4">
                    <span>SR Type</span>
                </div>
                <div class="w-full vx-col sm:w-3/4">
                    <multiselect
                        class="selectExample"
                        :multiple="false"
                        :select-label="''"
                        deselect-label=""
                        :placeholder="''"
                        :options="returnType"
                        label="name"
                        track-by="name"
                        :value="selectedType"
                        @input="(val) => { saveSelectedType(val) }"
                    />
                </div>
            </div>
            <div class="mb-6 vx-row" style="width:70%">
                <div class="flex items-center w-full vx-col sm:w-1/4">
                    <span>Date</span>
                </div>
                <div class="w-full vx-col sm:w-3/4">
                    <date-range-picker
                        style="min-height: 40px"
                        class="w-full"
                        ref="picker"
                        opens="center"
                        :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                        singleDatePicker="range"
                        :timePicker="false"
                        :timePicker24Hour="false"
                        :showWeekNumbers="false"
                        :showDropdowns="false"
                        :autoApply="true"
                        v-model="date"
                        :linkedCalendars="false"
                        :ranges="false"
                        control-container-class="border border-solid border-grey-light rounded p-3"
                        @select="(val) => saveDate(val)"
                    >
                        <template v-slot:input="picker">
                            <div class="">
                                {{ picker.startDate ? formatDate(picker.startDate) : '' }} - {{ picker.endDate ? formatDate(picker.endDate) : '' }}
                            </div>
                        </template>
                        <template v-slot:footer>
                            <div class="text-center mb-2">
                                <vs-button class="small warning" @click="clearDate">Clear</vs-button>
                            </div>
                        </template>
                    </date-range-picker>
                </div>
            </div>
            <div class="mb-6 vx-row" style="width:70%">
                <div class="flex items-center w-full vx-col sm:w-1/4">
                </div>
                <div class="w-full vx-col sm:w-3/4">
                    <vs-button color="primary" @click="handleFilter()">Filter</vs-button>
                </div>
            </div>
        </div>
        <div class="mt-3 vx-row">
            <div class="w-full vx-col mb-base">
                <Table :draw="draw" :status="activeTab" :transaction-date="transactionDate"
                    :source-warehouse-id="(this.selectedSrcWarehouse) ? this.selectedSrcWarehouse.id : this.defaultWarehouseID"
                    :supplier-id="(this.selectedSupplier)? this.selectedSupplier.id:0"
                    :date="this.date"
                    :ref-type="(this.selectedType)? this.selectedType.value.toString():''"
                    >
                </Table>
            </div>
        </div>
    </div>
</template>
<script>

import CustomMSelect from "./CustomMultiSelect.vue";
import Date from "./Date.vue";
import Table from "./ApprovedTable.vue";
import DateRangePicker from "vue2-daterange-picker";
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import moment from "moment"
export default {
    components: {
        CustomMSelect,
        Date,
        Table,
        DateRangePicker,
    },
    data() {
        return {
            returnType: [
                {
                    value: true,
                    name: "With reference",
                },
                {
                    value: false,
                    name: "Without reference",
                }
            ],
            activeTab: "Approved",
            draw: 0,
            transactionDate: null,
            selectedSrcWarehouse: this.$store.state.purchase.poReturn.warehouse,
            selectedSupplier: this.$store.state.purchase.poReturn.supplier,
            selectedType: this.$store.state.purchase.poReturn.type,
            date: this.$store.state.purchase.poReturn.date,
            roleIDs: [],
            superAdminIDs: [],
            warehouseAdminID: 0,
            warehouseStaffID: 0,
            warehouseManagerID: 0,
            defaultWarehouseID: 0,
            isSuperAdmin: false,
            isWarehouseMan: false,
        }
    },
    computed: {
        formatDate: () => {
            return (val) => {
                const momentDate = moment(val);
                return momentDate.format('YYYY-MM-DD');
            };
        },
    },
    mounted() {
        let restoredSrcWarehouse = localStorage.getItem('selectedSrcWarehouse')
        this.selectedSrcWarehouse = JSON.parse(restoredSrcWarehouse)

        let restoredSupplier = localStorage.getItem('selectedSupplier')
        this.selectedSupplier = JSON.parse(restoredSupplier)

        let restoredType = localStorage.getItem('selectedType')
        this.selectedType = JSON.parse(restoredType)

        this.$http.get("api/wms/v1/wms/purchase/po-return/get-role")
        .then((resp) => {
            if (resp.code == 200) {
                this.roleIDs = resp.data.user_roles
                this.superAdminIDs = resp.data.super_admin
                this.warehouseAdminID = resp.data.warehouse_admin
                this.warehouseStaffID = resp.data.warehouse_staff
                this.warehouseManagerID = resp.data.warehouse_manager
                this.defaultWarehouseID = resp.data.warehouse_id

                this.roleIDs.forEach(val => {
                    this.superAdminIDs.forEach(idx => {
                        if (idx == val) {
                            this.isSuperAdmin = true
                        }
                    })
                })

                this.roleIDs.forEach(val => {
                    if (val == this.warehouseAdminID || val == this.warehouseStaffID || val == this.warehouseManagerID) {
                        this.isWarehouseMan = true
                    }
                })

                if (this.isSuperAdmin == false) {
                    if (this.isWarehouseMan == false) {
                        this.$router.push({
                            name: "home",
                        });
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: "You are not part of Warehouse",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    }

                    if (this.defaultWarehouseID == 0) {
                        this.$router.push({
                            name: "home",
                        });
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: "This account is unbound with any warehouse",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    }
                }
            } else {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: resp.message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
            }
        })
        .catch((error) => {
            console.log(error)
            this.$vs.notify({
                color: "danger",
                title: "Error",
                text: error,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
            });
        });
    },
    methods: {
        clearDate() {
            this.saveDate({startDate: null, endDate: null})
            this.$refs.picker.togglePicker(false)
        },
        handleCreate() {
            this.$router.push({
                name: this.$store.state.purchase.poReturn.baseRouterName + ".draft",
            });
        },
        handleFilter() {
            this.draw++;
        },
        saveSelectedWarehouse(val) {
            this.$store.dispatch('purchase/poReturn/updateWarehouse', val)
            this.selectedSrcWarehouse = val
            // localStorage.setItem('selectedSrcWarehouse', JSON.stringify(val))
        },
        saveSelectedSupplier(val) {
            this.$store.dispatch('purchase/poReturn/updateSupplier', val)
            this.selectedSupplier = val
            // localStorage.setItem('selectedSupplier', JSON.stringify(val))
        },
        saveSelectedType(val) {
            this.$store.dispatch('purchase/poReturn/updateType', val)
            this.selectedType = val
            // localStorage.setItem('selectedType', JSON.stringify(val))
        },
        saveDate(val) {
            this.$store.dispatch('purchase/poReturn/updateDate', val)
            this.date = val
        }
    },
    watch: {
        defaultWarehouseID(val) {
            if (val) {
                this.draw++
            }
        }
    }
}
</script>