<template>
    <vx-card title="Purchase Order Return">
        <div class="flex vx-input-group">
            <vs-tabs v-if="render" v-model="$route.params.status">
                <vs-tab label="Draft">
                    <tab-draft></tab-draft>
                </vs-tab>
                <vs-tab label="Waiting">
                    <tab-waiting></tab-waiting>
                </vs-tab>
                <vs-tab label="Approved" disabled>
                    <tab-approved></tab-approved>
                </vs-tab>
                <vs-tab label="Released">
                    <tab-released></tab-released>
                </vs-tab>
                <vs-tab label="Rejected">
                    <tab-rejected></tab-rejected>
                </vs-tab>
                <vs-tab label="Canceled">
                    <tab-canceled></tab-canceled>
                </vs-tab>
                <vs-tab label="Closed">
                    <tab-closed></tab-closed>
                </vs-tab>
            </vs-tabs>
        </div>
    </vx-card>
</template>
<script>
import TabDraft from "./components/TabDraft.vue";
import TabApproved from "./components/TabApproved.vue";
import TabCanceled from "./components/TabCanceled.vue";
import TabClosed from "./components/TabClosed.vue";
import TabRejected from "./components/TabRejected.vue";
import TabReleased from "./components/TabReleased.vue";
import TabWaiting from "./components/TabWaiting.vue";
export default {
    components: {
        TabApproved,
        TabCanceled,
        TabClosed,
        TabDraft,
        TabRejected,
        TabReleased,
        TabWaiting,
    },
    data() {
        return {
            activeTab: "Draft",
            render: true,
        }
    },
    methods: {

    }
}
</script>
