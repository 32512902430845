<template>
    <multiselect
        :multiple="this.multiple"
        :allowEmpty="this.allowEmpty"
        @search-change="handleSearch"
        label="label"
        :track-by="this.trackBy ? this.trackBy : 'name'"
        :disabled="this.disabled"
        :readonly="this.readonly"
        :select-label="''"
        deselect-label=""
        :options="(this.optionsData && this.optionsData.length > 0) ? this.optionsData : (this.options ? this.options : [])"
        v-model="selected"
        :placeholder="placeholder ? placeholder : 'type to search'"
    />
</template>
<script>

export default {
    components: {
    },
    props: {
        multiple: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String
        },
        label: {
            type: String,
        },
        baseUrl: {
            type: String,
        },
        allowEmpty: {
            type: Boolean,
            default: true,
        },
        trackBy: {
            type: String,
        },
        value: {
        },
        options: {
            required: false
        },
        status: {
            required: false
        }
    },
    data() {
        return {
            typedValue: "",
            timeOuts: null,
            optionsData: [],
            selected: this.value ? this.value : {}
        };
    },
    methods: {
        handleSearch(v) {
            this.typedValue = v;
            if (this.timeOuts) { clearTimeout(this.timeOuts) }
            this.timeOuts = setTimeout(this.get, 400);
        },
        get() {
            this.$http.get(this.baseUrl, {
                params: {
                    order: "id",
                    sort: "asc",
                    query: this.typedValue,
                    status: this.status,
                    limit:10,
                }
            })
            .then(resp => {
                if (resp.code == 200) {
                    resp.data.records.map(function (x) {
                        return (x.label = x.code + " - " + x.name);
                    });

                    this.optionsData = resp.data.records;
                    if ((this.value == undefined || this.value == null) && resp.data.default) {
                        this.selected = resp.data.default;
                        this.$emit("selected", resp.data.default);
                    }
                } else {
                    this.optionsData = [];
                }
            })
            .catch(error => {
                console.log(error)
            });
        }
    },
    watch: {
        selected(v) {
            this.selected = v;
            this.$emit("selected", v);
        },
        value(v) {
            if (v) {
                this.selected = v
            }
        }
    },
    mounted() {
        if (this.baseUrl != "" && this.baseUrl != undefined) {
            this.get();
        } else if (this.options && this.options.length > 0) {
            this.optionsData = this.options
        }
    }
};
</script>
<style>
.multiselect--above .multiselect__content-wrapper {
    bottom: auto !important;
    top: 100% !important;
}
</style>